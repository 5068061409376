
onResourcesAndDOMReady(function () {

    jQ('#right_content .wrap_uc_faccebook').fadeIn(3000);

    jQ(window).load(function () {
        Track();
    });

    const forms = document.querySelectorAll('form');
    forms.forEach(function(elem){
        if (!elem.closest('.entry-content')) {
            const verification = document.createElement("input");
            verification.setAttribute('type', 'hidden');
            verification.setAttribute('name', 'verification');
            verification.setAttribute('value', '1');
            elem.appendChild(verification);
        }
    });

    // jQ("form").each(function (index, elem) {
    //     if (!jQ(elem).closest('.entry-content').length) {
    //         jQ(elem).append('<input type="hidden" name="verification" value="1">');
    //     }
    // });

    jQ('.right_search_form input[name="verification"], .wrap_refine_search input[name="verification"]').remove();

//add hidden input for reply form to prevent spam
//     if (jQ("input[name='action'][value='reply_by_email']").length) {
//         jQ("input[name='action'][value='reply_by_email']").each(function (index, elem) {
//             jQ(elem).parents('form').append('<input type="hidden" name="reply_check_input" value="1">');
//         });
//     }

    if (document.querySelector("input[name='action'][value='reply_by_email']")) {
        const elements = document.querySelectorAll("input[name='action'][value='reply_by_email']");
        elements.forEach(function(elem){
            const reply_check_input = document.createElement("input");
            reply_check_input.setAttribute('type', 'hidden');
            reply_check_input.setAttribute('name', 'reply_check_input');
            reply_check_input.setAttribute('value', '1');
            const form = elem.closest('form');
            if (form) {
                form.appendChild(reply_check_input);
            }
            // jQ(elem).parents('form').append('<input type="hidden" name="reply_check_input" value="1">');
        });
    }


// if no image
    /*
     jQ('.detail_main_image img').error(function(){
     jQ(this).attr('src', '/img/no-image-small.jpg');
     var banner = "<div class='banner_336x280_center'>\
     <script async src='//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'></script>\
     <!-- All other sections - 336x280 -->\
     <ins class='adsbygoogle'\
     style='display:inline-block;width:336px;height:280px'\
     data-ad-client='ca-pub-1698388548110737'\
     data-ad-slot='2034686390'></ins>\
     <script>\
     (adsbygoogle = window.adsbygoogle || []).push({});\
     </script>\
     </div>";

     jQ('.detail_main_image').html(banner);
     });*/

    addErrorIMGEvent();

    jQ('.ad_view .user-profile-image-inner img').error(function(){
        jQ(this).parents('.sidebar-user-avatar').remove();
    });

    jQ('.post_rentbooks .block_for_image .display_table a img, .right_links_item .block_for_image img, .post .post_image img, .block_for_image .display_table a img').error(function () {
        jQ(this).attr('src', window.imagesDomain + '/img/no-image-small2.jpg');
    });

    jQ('.news_author_blok img.photo, .detail_small_images img, .tutors_list .block_for_image .display_table a img').error(function () {
        jQ(this).attr('src', window.imagesDomain + '/img/no-image-small.jpg');
    });
    jQ('.detail_main_image img').error(function () {
        jQ(this).attr('src', window.imagesDomain + '/img/no-image-small.jpg');

        // uncomment if you want remove ads with broken photos from import
        //var listingId = null;
        //var re = /\/housing\/view\.php\/(\d+)\/.+/;
        //var pathname = window.location.pathname;
        //var match;
        //
        //if ((match = re.exec(pathname)) !== null) {
        //    if(match[1] !== undefined && match !== null) {
        //        listingId = match[1];
        //    }
        //}
        //
        //if(listingId) {
        //    jQ.ajax({
        //        url: 'ajax_content.php',
        //        data:{
        //            action:'remove_ad_from_import',
        //            listing_id:listingId
        //        },
        //        type: 'POST',
        //        dateType: 'json'
        //    });
        //}
    });
//
//jQ('.textbook .tb-img img').error(function(){ jQ(this).attr('src', 'test');
////        jQ(this).attr('src', window.imagesDomain+'/img/partner_logo/chegg-small.jpg');
//});

//jQ('.textbook_detail .tb-d-img img').on('error',function(){
//	jQ(this).attr('src', window.imagesDomain+'/img/no-image-small.jpg');
//});
//
//
//jQ('.textbook .tb-img img').on('error',function(){
//	jQ(this).attr('src', window.imagesDomain+'/img/no-image-small.jpg');
//});
    jQ('.recently_sold_textbooks .tb-img img').each(function () {
        if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
            errorTextBookRecentlySold(this);
        }
        jQ(this).attr('onerror', 'errorTextBookRecentlySold(this);');
    });

    jQ('.textbook_detail .tb-d-img img').each(function () {
        if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
            errorTextBookCover(this);
        }
        jQ(this).attr('onerror', 'errorTextBookCover(this);');
    });
    jQ('.textbook .tb-img img').each(function () {
        if (jQ(this).closest('.recently_sold_textbooks').length) {
            return;
        }
        if (this.complete && ( this.naturalHeight === 0 || this.naturalWidth === 0 )) {
            errorTextBookCover(this);
        }
        jQ(this).attr('onerror', 'errorTextBookCover(this);');
    });

    //var state = jQ('.main_school_select #state :first').text();
    var w = jQ(window).width();
    if (w < 500) {
        jQ('.main_school_select #state :first').text('State');
        jQ('.main_school_select #school :first').text('University');
    }
    ;

// top menu responsive tabs start
    var k = 0;
    jQ(".left_menu li").click(function (event) {

        var w = jQ(window).width();

        if (w <= 969 && jQ.support.opacity) {
            jQ('#mobile_menu .left_menu li a b,#mobile_menu .right_menu li a b').removeClass('open');
//		jQ(this).find("a").prop("href", "javascript: void(0);");
            jQ('.main_menu_bottom_l').hide();

            if (k == 0) {
                jQ('.main_menu_bottom_r').slideDown(300);
                jQ('#main_menu_bg_l').css({'border-top': '37px solid #F4F4F4'});
                k = 1;
            }
            else if (k == 1) {
                jQ('.main_menu_bottom_r').slideUp(300);
                k = 0;
                jQ('#main_menu_bg_l').css({'border-top': '36px solid #F4F4F4'});
            }
            else {
                jQ('.main_menu_bottom_r').slideDown(300);
                jQ('#main_menu_bg_l').css({'border-top': '37px solid #F4F4F4'});
                k = 1;
            }

            if (k == 1) {
                jQ('b', this).addClass('open');
            }
        }
        else {
            jQ('#main_menu_bg_l').css({'border-top': '36px solid #F4F4F4'});
            jQ('.main_menu_bottom_r').hide();
//                var lstLink = jQ(".news_menu_bottom li:last").find("a").attr('href');
//		jQ(".left_menu").find("a:first").prop("href", lstLink);
        }
        ;
    });

    jQ(".right_menu li").click(function () {
        var w = jQ(window).width();
        if (w < 969 && jQ.support.opacity) {
            jQ('#mobile_menu .left_menu li a b,#mobile_menu .right_menu li a b').removeClass('open');
//		jQ(this).find("a").prop("href", "javascript: void(0);");
            jQ('.main_menu_bottom_r').hide();

            if (k == 0) {
                jQ('.main_menu_bottom_l').slideDown(300);
                k = 2;
            }
            else if (k == 2) {
                jQ('.main_menu_bottom_l').slideUp(300);
                k = 0;
            }
            else {
                jQ('.main_menu_bottom_l').slideDown(300);
                k = 2;
            }
            ;
        }
        else {
//                var lstLink = jQ(".class_menu_bottom li:last").find("a").attr('href');
//                jQ(".right_menu").find("a:first").prop("href", lstLink);
            jQ('.main_menu_bottom_l').hide();
        }
        ;

        if (k == 2) {
            jQ('b', this).addClass('open');
        }
    });
// top menu responsive tabs end


    var ua = navigator.userAgent.toLowerCase();
    var isAndroid = ua.indexOf("android") > -1;
    if (!isAndroid) {

        jQ("body").on("keydown", ".NumGroup", function (e) {
            return CampusNumberInputOnKeyPress(e, jQ(this).val());
        });

        if (typeof(jQuery.fn.groupinputs) !== 'undefined') {
            if (jQ('.NumGroup').length) {
                jQ('.NumGroup').groupinputs();
            }
        }
    }

    jQ(".log_in, .non-logged-user-action, .non-logged-user-box, .open-login").click(function (event) {
        var popup_id = jQ(this).attr("rel");

        if(!jQ('#'+popup_id).length && window.CodeAdapter.project_type === 'wordpress_plugin'){
            return false;
        }

        if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
            window.location.href = '/customer_register.htm';
            return false;
        }


        var _this = this;

        var showStudentPosting = false;
        var additionalParam = '';
        if (jQ(_this).closest('.middle-post-box').length) {
            showStudentPosting = true;
            additionalParam = 'track-student-posting-login'
        }

        var processLoginType = function(){};

        if (jQ(_this).attr("option") === 'main-login') {

            processLoginType = function() {
                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    jQ('#' + popup_id + ' .sgn_in_popup').width(750);
                }
                jQ('#' + popup_id + ' .sgn_in_popup_rigth').show();

                jQuery('.login-post-buttons').hide();
                jQuery('.login-post-buttons.default').show();

                var post_type = jQ(_this).attr("options-type");
                if (post_type && post_type !== 'default') {
                    jQ('.login-post-buttons').hide();
                    jQ('.login-post-buttons.' + post_type).show();

                    jQ('.login-sign-up-link').hide();
                    jQ('.login-sign-up-link.' + post_type).show();
                }
                // else if (window.CodeAdapter.project_type !== 'wordpress_plugin'){
                //
                //     console.log('asdfasdf afafasdf');
                //     jQ('.sgn_in_popup_left').css({
                //         'padding-right' : 0,
                //         'border-right' : 'none'
                //     });
                // }
            };

            if (typeof ConversionTracking !== typeof undefined) {
                new ConversionTracking().triggerAction({action_id: 17});
            }
        }
        else {
            processLoginType = function() {
                if (window.CodeAdapter.project_type === 'wordpress_plugin') {
                    jQ('#' + popup_id + ' .sgn_in_popup').width(372);
                } else {
                    jQ('.sgn_in_popup_left').css({
                        'padding-right' : 0,
                        'border-right' : 'none'
                    });
                }
                jQ('#' + popup_id + ' .sgn_in_popup_rigth').hide();

            };


        }
        if (window.CodeAdapter.project_type === 'wordpress_plugin') {

            processLoginType();
            popup({
                popup_id: popup_id
            });
        } else {
            requirejs(['popup/signIn'], function (sign_in) {
                sign_in.instance.contentLoaded = processLoginType;
                sign_in.instance.open({
                    openStudentPostingAfterLogin: showStudentPosting,
                    additionalParam: additionalParam,
                });
            });
        }

        return false;
    });


    //OTHER COLLEGES
    (function () {
        jQ('.show_schools_list').each(function () {
            var i = 0;
            jQ(this).click(function () {

                if (jQ(this).attr("id") == 'browse_all_cities') {
                    var container = jQ(this).parent().find(".browse_college_container").get(0);
                    var clicked_link = jQ(this).get(0);

                    if (!jQ(container).children().length) {

                        jQ(clicked_link).after('<div class="school-state-preloader" align="center"><img src="' + window.imagesDomain + '/img/small-loading-white.gif"/></div>');

                        window.CodeAdapter.ajaxRequest({
                            url: "ajax_content.php",
                            type: "POST",
                            data: {action: 'get_cities_json'},
                            dataType: "json",
                            success: function (json) {
                                container.innerHTML = '';
                                for (key in json) {
                                    var city = json[key];
                                    jQ(container).append('<div class="list_title" id="city_' + city.id + '"><span>' + city.title + '</span>\
                                                                                    <a class="browse_see_all" href="' + city.url + '">(see all)</a>\
                                                                                  </div>\
                                                                                  <div class="school-state-container"></div>\n\
                                                                                  ');
                                }

                                jQ('.school-state-preloader').remove();

                                jQ('.right_content_list li .show_schools_list').css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                                jQ(clicked_link).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px -13px");
                                jQ('.schools_list').slideUp(500);
                                jQ(clicked_link).next('.schools_list').slideDown(500);
                            }
                        });
                    }
                    else {
                        if (jQ(clicked_link).next('.schools_list').is(":visible")) {
                            jQ(clicked_link).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                            jQ(clicked_link).next('.schools_list').slideUp(500);
                        }
                        else {
                            jQ('.right_content_list li .show_schools_list').css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                            jQ(clicked_link).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px -13px");
                            jQ('.schools_list').slideUp(500);
                            jQ(clicked_link).next('.schools_list').slideDown(500);
                        }
                    }
                }
                else if (jQ(this).attr("id") == 'browse_all_states') {
                    var container = jQ(this).parent().find(".browse_college_container").get(0);
                    var clicked_link = jQ(this).get(0);

                    if (!jQ(container).children().length) {

                        jQ(clicked_link).after('<div class="school-state-preloader" align="center"><img src="' + window.imagesDomain + '/img/small-loading-white.gif"/></div>');

                        window.CodeAdapter.ajaxRequest({
                            url: "ajax_content.php",
                            type: "POST",
                            data: {action: 'get_states_json'},
                            dataType: "json",
                            success: function (json) {
                                container.innerHTML = '';
                                for (key in json) {
                                    var state = json[key];
                                    jQ(container).append('<div class="list_title" id="state_' + state.id + '"><span>' + state.title + '</span>\
                                                                                    <a class="browse_see_all" href="' + state.url + '">(see all)</a>\
                                                                                  </div>\
                                                                                  <div class="school-state-container"></div>\n\
                                                                                  ');
                                }

                                jQ('.school-state-preloader').remove();

                                jQ('.right_content_list li .show_schools_list').css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                                jQ(clicked_link).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px -13px");
                                jQ('.schools_list').slideUp(500);
                                jQ(clicked_link).next('.schools_list').slideDown(500);
                            }
                        });
                    }
                    else {
                        if (jQ(clicked_link).next('.schools_list').is(":visible")) {
                            jQ(clicked_link).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 5px");
                            jQ(clicked_link).next('.schools_list').slideUp(500);
                        }
                        else {
                            jQ('.right_content_list li .show_schools_list').css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                            jQ(clicked_link).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px -13px");
                            jQ('.schools_list').slideUp(500);
                            jQ(clicked_link).next('.schools_list').slideDown(500);
                        }
                    }
                }
                else {
                    if (jQ(this).next('.schools_list').is(":visible")) {
                        jQ(this).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                        jQ(this).next('.schools_list').slideUp(500);
                    }
                    else {
                        jQ('.right_content_list li .show_schools_list').css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px 2px");
                        jQ(this).css("background", "url(" + window.imagesDomain + "/img/arrow_list_sp3-op.png) no-repeat 0px -13px");
                        jQ('.schools_list').slideUp(500);
                        jQ(this).next('.schools_list').slideDown(500);
                    }
                }
                return false;
            });
        });

    })();


    jQ('body').on('mouseover', '.browse_college_container .list_title', function () {
        jQ(this).find('.browse_see_all').show();
    });

    jQ('body').on('mouseout', '.browse_college_container .list_title', function () {
        jQ(this).find('.browse_see_all').hide();
    });


    jQ('body').on('click', '.browse_college_container .list_title span', function () {

        var container = jQ(this).parent().next('.school-state-container').get(0);
        var clicked_link = jQ(this).parent().get(0);

        if (!jQ(container).children().length) {

            var city_state_origin = jQ(this).parent().attr('id');

            if (city_state_origin.indexOf('city_') !== -1) {
                var city_state = jQ(this).parent().attr('id').replace('city_', '');
                var city_state_action = 'get_schools_by_city_json';
            }
            else if (city_state_origin.indexOf('state_') !== -1) {
                var city_state = jQ(this).parent().attr('id').replace('state_', '');
                var city_state_action = 'get_schools_by_state_json';
            }

            if (city_state.length && city_state_action.length) {

                jQ(clicked_link).after('<div class="school-state-preloader" align="center"><img src="' + window.imagesDomain + '/img/small-loading-white.gif"/></div>');

                window.CodeAdapter.ajaxRequest({
                    url: "ajax_content.php",
                    type: "POST",
                    data: {action: city_state_action, "city_state": city_state},
                    dataType: "json",
                    success: function (json) {
                        container.innerHTML = '';
                        for (key in json) {
                            var school = json[key];
                            var link = document.createElement('a');

                            link.setAttribute('href', school.URL);
                            link.innerHTML = school.Title;
                            container.appendChild(link);
                        }

                        jQ('.school-state-preloader').remove();

                        if (jQ(container).is(":visible")) {
                            jQ(container).slideUp(500);
                        }
                        else {
                            jQ('.school-state-container').slideUp(300);
                            jQ(container).slideDown(500);
                        }
                    }
                });
            }
        }
        else {
            if (jQ(container).is(":visible")) {
                jQ(container).slideUp(500);
            }
            else {
                jQ('.school-state-container').slideUp(300);
                jQ(container).slideDown(500);
            }
        }


        return false;
    });

    jQ('#spc-form').on('submit', function (e) {
        e.preventDefault();
        var form = jQ("#spc-form");
        // var validate = Validate_Data(jQ('input, select, textarea, checkbox', form), false);
        //
        // if (!validate) {
        //     return false;
        // }

        ShowPopup('custom_preloader');

        if (window.CodeAdapter.project_type === 'wordpress_plugin') {
            var blank_window = window.open('about:blank', '_blank');
        } else {
            var blank_window = false;
        }

        window.CodeAdapter.ajaxRequest({
            url: "index.php",
            type: "POST",
            data: jQ(form).serialize(),
            dataType: "json",
            async: false, // for prevent blocking
            success: function (data) {
                if (typeof(data.trigger_error_label) !== 'undefined' && data.response == false) {
                    closePopup('custom_preloader');
                    var error_label = jQ('[id="' + data.trigger_error_label + '"][disabled!="disabled"]', form).get(0);
                    SetErrorText(error_label, data.error, false);
                    BlinkElem(error_label);
                }
                else if (typeof(data.response) !== 'undefined' && data.response) {
                    var form_copy = jQ(form).clone();
                    jQ('[name="action"], [name="partner"], [name="partner"]', form_copy).remove();

                    if (window.CodeAdapter.project_type === 'wordpress_plugin' && blank_window) {
                        blank_window.location = plugin_data.uloop_base_domain + '/view_external_link.php?' + jQ(form_copy).serialize();
                    } else {
                        window.open('/view_external_link.php?' + jQ(form_copy).serialize(), '_blank');
                    }
                    form[0].reset();

                    closePopup('custom_preloader');
//					var popup = '<div class="spc-quote-popup"><h2>Thank you for submitting<br>your Quote.<br/> A college specialist will contact you shortly.</h2></div>';
//					jQ('#replies_detail').html(popup);
//					ShowPopup('replies_detail');
                } else {
                    if (window.CodeAdapter.project_type === 'wordpress_plugin' && blank_window) {
                        blank_window.close();
                    }

                    closePopup('custom_preloader');
                }
            }
        });
    });

});

onResourcesAndDOMReady(function () {
// MOVING FORM
    if (jQ('#moving_quote_form').get(0)) {

        jQ('body').on("keyup", ".phone", function (e) {
            FormatPhone(e, this)
        });

        jQ('#moving_quote_form').on('submit', function (e) {
            e.preventDefault();
            var form = jQ("#moving_quote_form");

            var where = jQ(form).find("#mv_where").get(0);
            if (where.selectedIndex < 1) {
                CMPS_SetErrorText($('mv_where-error'), 'Please select city');
                jQ("body").on("change", "#mv_where", function () {
                    if ($('mv_where-error')) $('mv_where-error').innerHTML = '';
                });
                e.preventDefault();
                return false;
            }

            var when = jQ(form).find("input[name='reservation_start']").get(0);
            var whenERROR = jQ(form).find("#mv_when-error").get(0);
            if (!validationInput(when, whenERROR)) {
                e.preventDefault();
                return false;
            }

            var email = jQ(form).find("input[name='customer_email']").get(0);
            var emailERROR = jQ(form).find("#mv-email-error").get(0);
            if (!validationEmail(email, emailERROR)) {
                e.preventDefault();
                return false;
            }

            var phone = jQ(form).find("input[name='customer_phone']").get(0);
            var phoneERROR = jQ(form).find("#mv-phone-error").get(0);
            if (!validatePhoneNumber(phone, phoneERROR)) {
                e.preventDefault();
                return false;
            }

            var formCopy = jQ(form);

            ShowPopup('custom_preloader');

            window.CodeAdapter.ajaxRequest({
                url: "index.php",
                type: "POST",
                data: {
                    action: "handle_moving_page_form",
                    zip: jQ(where).val(),
                    when: jQ(when).val(),
                    email: jQ(email).val(),
                    phone: jQ(phone).val(),
                    partner: jQ(form).find("input[name='partner']").val()
                },
                dataType: "json",
                success: function (response) {
                    if (response.success) {
                        if (typeof(plugin_data) !== 'undefined') {
                            jQ("body").append('<iframe src="' + plugin_data.uloop_base_domain + '/view_external_link.php?' + jQ(form).serialize() + '" frameborder="none" height="0" width="0"></iframe>');
                        } else {
                            jQ("body").append('<iframe src="/view_external_link.php?' + jQ(form).serialize() + '" frameborder="none" height="0" width="0"></iframe>');
                        }
                        form[0].reset();

                        closePopup('custom_preloader');
                        var popup = '<div class="mv-help-popup"><h2>Thank you for submitting<br>your Moving Quote.<br/> A college moving specialist will contact you shortly.</h2></div>';

                        jQ('#replies_detail').html(popup);
                        ShowPopup('replies_detail');
                    }
                }
            });
        });

        var dateToday = new Date();
        jQ("#mv_when").datepicker({
            minDate: dateToday
        });
    }


    /*
     jQ('.school-state-link').click(function(){

     var container = jQ(this).next('.school-state-container').get(0);
     var clicked_link = jQ(this).get(0);

     if(!jQ(container).children().length)
     {


     var state = jQ(this).attr('rel');
     jQ(clicked_link).after('<div class="school-state-preloader" align="center"><img src="'+window.imagesDomain+'/img/small-loading-white.gif"/></div>');

     jQ.ajax({
     url: "ajax_content.php",
     type: "POST",
     data: { action : 'get_schools_by_state_json',"state" : state},
     dataType: "json"
     }).done(function( json ) {

     container.innerHTML = '';
     for (key in json) {
     var school = json[key];
     var link = document.createElement('a');

     link.setAttribute('href',school.URL);
     link.innerHTML = school.Title;
     container.appendChild(link);
     }

     jQ('.school-state-preloader').remove();

     if(jQ(container).is(":visible")){
     jQ(container).slideUp(500);
     }
     else{
     jQ('.school-state-container').slideUp(300);
     jQ(container).slideDown(500);
     }

     });
     }
     else
     {
     if(jQ(container).is(":visible")){
     jQ(container).slideUp(500);
     }
     else{
     jQ('.school-state-container').slideUp(300);
     jQ(container).slideDown(500);
     }
     }


     return false;
     });*/

    //
    // jQ('*[options="login-required"]').click(function () {
    //     showLoginDialog();
    //     return false;
    // });ogin-required

    jQ('body').on('click', '*[options="login-required"]', function (e) {
        showLoginDialog(this);
        return false;
    });


    //REPLY FORM

    jQ('body').on('click', '.top_reply .reply-button', function () {
        jQ('.top_reply_form .reply-form, .top_reply_form .reply-form-special').slideToggle(300);
        //jQ('.bottom_reply_form .reply-form').slideUp();
        jQ("p.reply_sended").remove();
        return false;
    });
    jQ('body').on('click', '.bottom_reply .reply-button', function () {
        jQ('.bottom_reply_form .reply-form, .bottom_reply_form .reply-form-special').slideToggle(300);
        //jQ('.bottom_reply_form .reply-form').slideDown();
        //jQ('.top_reply_form .reply-form').slideUp();
        jQ("p.reply_sended").remove();
        return false;
    });
    jQ('body').on('click', '.popup-scrollable p.reply_sended', function () {
        jQ(this).remove();
        return false;
    });
    jQ('body').on('click', '.cancel_reply', function () {
        jQuery("#preloader").remove();
        jQ(this).parents('.reply-form, .reply-form-special').slideUp();
        return false;
    });

    //POSTING AD CUSTOM FIELDS

    AdPostingCustomFields();

    jQ('#ad-global-channel').change(function () {
        AdPostingCustomFields();
    });
    jQ('#ad-main-subcategories-list').change(function () {
        AdPostingCustomFields();
    });
    jQ('#ad-info-house-property-type').change(function () {
        AdPostingCustomFields();
    });


    //STAR INPUT

    var wrap = function (toWrap, wrapper) {
        wrapper = wrapper || document.createElement('div');
        toWrap.parentNode.appendChild(wrapper);
        return wrapper.appendChild(toWrap);
    };

    jQ('.star_input').each(function (index, elem) {
        // jQ(elem).wrap('<span class="star_cont" />');
        var  wrapper = document.createElement('span')
        wrapper.setAttribute('class', 'star_cont');
        wrap(elem, wrapper);

        var starButton = document.createElement('div')
        starButton.setAttribute('class', 'star_empty star_button');
        elem.parentNode.appendChild(starButton);

        elem.style.display = 'none';
        // jQ(elem).wrap('<span class="star_cont" />');
        // jQ(elem).parent().append('<div class="star_empty star_button"></div>');
        // jQ(elem).hide();
    });


    jQ('.star_button').click(function () {
        var name = jQ(this).parent().find('input').attr('name');
        var value = jQ(this).parent().find('input').attr('value');
        jQ('.star_cont input[name="' + name + '"]').prop('checked', false);
        jQ(this).parent().find('input').prop('checked', true);

        jQ('.star_cont input[name="' + name + '"]').each(function (index, elem) {

            if (jQ(elem).attr('value') <= value) {
                jQ(elem).parent().find('.star_button').removeClass('star_empty').addClass('star_full');
            }
            else {
                jQ(elem).parent().find('.star_button').removeClass('star_full').addClass('star_empty');
            }

        });

    });

    //FROM VALIDATION

    jQ('.validate_form').submit(function () {

        var form = this;
        if (jQ(form).find('input[name="validated"]').val() == 'true') {
            return true;
        }

        window.CodeAdapter.ajaxRequest({
            type: "POST",
            url: "index.php",
            data: jQ(this).serialize() + '&validate=true',
            dataType: "json",
            success: function (json) {
                if (json.success) {
                    jQ(form).append('<input type="hidden" name="validated" value="true">');
                    jQ(form).submit();
                }
                else {
                    //campus-posting-error-message
                    jQ('.validator_error').remove();
                    jQ('.campus-posting-error-message').hide();
                    if (jQ('#' + json.field_name + '-error').length) {
                        jQ('#' + json.field_name + '-error').html(json.message);
                        jQ('#' + json.field_name + '-error').show();
                        CMPS_blink($(json.field_name + '-error'));
                        CMPS_ScrollToElement($(json.field_name + '-error'));
                    }
                    else {
                        jQ('.validate_form *[name="' + json.field_name + '"]').after('<span id="' + json.field_name + '-error" class="campus-posting-error-message validator_error" style="visibility: visible;display:block;">' + json.message + '</span>');

                        CMPS_blink($(json.field_name + '-error'));
                        CMPS_ScrollToElement($(json.field_name + '-error'));
                    }
                }
            }
        });

        return false;
    });

    //WP plugin professors send department form
    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        jQuery('#sidebar_department').change(function () {
            if (jQuery(this).val() > 0) {
                window.location = '/' + plugin_data.uloop_university_slug + '/professor-ratings/?department_id=' + jQuery(this).val();
            }
        });
    } else {
        jQ('#sidebar_department').change(function () {
            if (jQ(this).val() != '0')
                window.location = '/professors?' + jQ(this).attr('name') + '=' + jQ(this).val();
            else
                window.location = '/professors';
        });
    }

    jQ('#sidebarF_state').change(function () {
        jQ("#sidebarF_college").children().remove();
        window.CodeAdapter.ajaxRequest({
            url: "index.php",
            data: jQ("#college_filter").serialize(),
            dataType: "html",
            type: "POST",
            cache: "false",
            success: function (date) {
                jQ("#sidebarF_college").append(date);
            }
        });
        return false;
    });

    jQ('#sidebarF_college').change(function () {
        if (jQ(this).val() != '0')
            window.location = '/news-team?state=' + jQ("#sidebarF_state option:selected").val() + '&' + jQ(this).attr('name') + '=' + jQ(this).val();
        else
            window.location = '/news-team';
    });


    jQ('body').on('click', '.right_to_edit .delete_ads', function () {
        var URL = AJAXBaseURL + '/ajaxapi.php?delete_from_page=1&ads[]=' + jQ('.right_to_edit .delete_ads').attr('id');
        dhtmlCampusAveLoadScript(URL);
        return false;
    });

    jQ("#ad-info-phoneP1, #ad-info-phoneP2, #ad-info-phoneP3, input[name$='phone1'], input[name$='phone2'], input[name$='phone3']").keydown(function (event) {
        if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 ||
            (event.keyCode == 65 && event.ctrlKey === true) ||
            (event.keyCode >= 35 && event.keyCode <= 39)) {
            return;
        }
        else {
            if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
                return false;
            }
        }
    });

    jQ('body').on('click', '.question-buttons-bar .qustion-share-btn', function () {
        var elem = jQ(this);
        if (!jQ(elem).hasClass('shown')) {
            jQ(elem).parent().find('.qa-share-menu').fadeIn(100, function () {
                jQ(elem).addClass('shown');
            });
        } else {
            jQ(elem).parent().find('.qa-share-menu').fadeOut(100, function () {
                jQ(elem).removeClass('shown');
            });
        }
    });

    jQ('body').on('change', '.form-field-container .form-text-field', function () {
        if(jQ(this).val().length){
            jQ(this).addClass('filled');
        } else {
            jQ(this).removeClass('filled');
        }
    });
});




onResourcesAndDOMReady(function () {

    var inputs = document.getElementsByTagName('input');

    for (index = 0; index < inputs.length; ++index) {
        // deal with inputs[index] element.

        if (inputs[index].getAttribute('default-value')) {

            inputs[index].value = inputs[index].getAttribute('default-value');
            inputs[index].onfocus = function () {
                if (this.value == this.getAttribute('default-value'))
                    this.value = '';
            }
            inputs[index].onblur = function () {
                if (this.value == '')
                    this.value = this.getAttribute('default-value');
            }
        }
    }

    jQ('body').on("keydown", ".phone", function (e) { FormatPhone(e, this) });

    jQ("body").on("keydown", ".only_num", function (e) {
        return CampusNumberInputOnKeyPress(e, jQ(this).val());
    });

    var path = window.location.pathname.substring(1);

    if (jQ('#states-links').get(0)) {

        var states_links = jQ('#states-links').get(0).getElementsByTagName('a');


        for (var i = 0; i < states_links.length; i++) {
            var state_link = states_links[i];
            var option = document.createElement('option');
            option.value = state_link.getAttribute('rel');
            option.innerHTML = state_link.innerHTML;
            $('state').appendChild(option);

            state_link.style.display = 'none';
        }

        $('state').onchange = function () {
            var state = this.value;

            $('school').disabled = "disabled";
            $('school').options.length = 0;

            var option = document.createElement('option');
            option.value = "";
            option.innerHTML = "Select your University";
            $('school').appendChild(option);

            if (state) {
                window.CodeAdapter.ajaxRequest({
                    url: "ajax_content.php",
                    type: "POST",
                    data: {action: 'get_schools_by_state_json_sel_col', state: state},
                    dataType: "json",
                    success: function (json) {
                        for (key in json) {
                            var school = json[key];
                            var option = document.createElement('option');
                            option.setAttribute('value', school.URL);
                            option.innerHTML = school.Title;
                            $('school').appendChild(option);
                        }

                        $('school').removeAttribute('disabled');
                    }
                });
            }

        }
        $('go').onclick = function () {
            if ($('school').value) {
                window.location = $('school').value + '/' + window.location.pathname.substring(1);
            }
        }

    }


    if (jQ('#states-links-post').get(0) && jQ('#state-post').get(0)) {
        var states_links = jQ('#states-links-post').get(0).getElementsByTagName('a');

        for (var i = 0; i < states_links.length; i++) {
            var state_link = states_links[i];
            var option = document.createElement('option');
            option.value = state_link.getAttribute('rel');
            option.innerHTML = state_link.innerHTML;
            $('state-post').appendChild(option);

            state_link.style.display = 'none';
        }

        $('state-post').onchange = function () {
            var state = this.value;

            $('school-post').disabled = "disabled";
            $('school-post').options.length = 0;

            var option = document.createElement('option');
            option.value = "";
            option.innerHTML = "Select your University";
            $('school-post').appendChild(option);

            if (state) {
                window.CodeAdapter.ajaxRequest({
                    url: "ajax_content.php",
                    type: "POST",
                    data: {action: 'get_schools_by_state_json', state: state},
                    dataType: "json",
                    success: function (json) {
                        for (key in json) {
                            var school = json[key];
                            var option = document.createElement('option');
                            option.setAttribute('value', school.URL);
                            option.innerHTML = school.Title;
                            $('school-post').appendChild(option);
                        }

                        $('school-post').removeAttribute('disabled');
                    }
                });
            }

        }
        /*var states_links = $('states-links-post').getElementsByTagName('a');
         var schools_links = $('schools-links-post').getElementsByTagName('a');

         for (var i=0; i<states_links.length; i++) {
         var state_link = states_links[i];
         state_link.style.display = 'none';
         }

         for (var i=0; i<schools_links.length; i++) {
         var school_link = schools_links[i];
         school_link.style.display = 'none';
         }

         for (var i=0; i<states_links.length; i++){
         var state_link = states_links[i];
         var option = document.createElement('option');
         option.value = state_link.getAttribute('rel');
         option.innerHTML = state_link.innerHTML;
         $('state-post').appendChild(option);

         state_link.style.display = 'none';
         }

         $('state-post').onchange = function (){
         var state = this.value;

         var schools = $('school-post').getElementsByTagName('option');
         for(var i=0; i<schools.length; i++){
         var school = schools[i];
         if(i>0){
         school.parentNode.removeChild(school);
         }
         i++;
         }
         $('school-post').options.length = 0;
         var option = document.createElement('option');
         option.value="";
         option.innerHTML="Select your University";
         $('school-post').appendChild(option);
         if(state){
         $('school-post').removeAttribute('disabled');
         for(var i=0; i<schools_links.length; i++){
         var school_link = schools_links[i];
         if(school_link.getAttribute('rel') == state){
         var option = document.createElement('option');
         option.setAttribute('value',school_link.getAttribute('href'));
         option.setAttribute('id',school_link.getAttribute('id'));
         option.innerHTML = school_link.innerHTML;
         $('school-post').appendChild(option);
         }
         }
         }
         else{
         $('school-post').disabled="disabled";
         }
         }*/
    }

    if (jQ('#post_ads').get(0)) {
        $('post_ads').onclick = function () {
            var schoolId = jQ('#register_user_and_choise_school input[name$="school_i"]').val();

            if (!jQ('#school-post option:selected').val().length) {
                var state = $("state-post");
                var school = $("school-post");
                var schoolErorr = $("select-school-error");
                CMPS_SetErrorText_noneScroll(schoolErorr, jQ(schoolErorr).text());
                school.focus();
                school.change = function () {
                    if (schoolErorr) {
                        schoolErorr.style.visibility = 'hidden';
                        this.onkeydown = null;
                    }
                }
                return false;
            }
            else {
                if (schoolId > 0) {
                    var selectedSchool = jQ('#school-post option:selected').attr('id').replace('select_', '');
                    window.CodeAdapter.ajaxRequest({
                        url: "profile.htm",
                        data: "action=" + jQ('#register_user_and_choise_school input[name$="action"]').val() + "&newVal=" + selectedSchool,
                        type: "POST",
                        dataType: "html",
                        success: function (data) {
                            window.location = $('school-post').value + '/post_ads.htm';
                        }
                    });
                }
                else {
                    window.location = $('school-post').value + '/post_ads.htm';
                }
            }
            return false;
        }
    }


    processUloopExternalLinks();

    jQ("a[view_internal_link]").each(function () {
        jQ(this).attr('href', '/view_internal_link.php?' + jQ(this).attr('view_internal_link'));
        if (jQ(this).attr('target') !== '_self') {
            jQ(this).attr('rel', 'nofollow');
            jQ(this).attr('target', '_blank');
        }
    });


    jQ('body').click(function (e) {
        if (window.results_container && !jQ(e.target).is('input') && !jQ(e.target).parents('#search_popup').length) {
            jQ(window.results_container).css("display", "none");
            if (typeof(jQ('.header-search-form-holder')) !== "undefined") {
                var form = jQ('.header-search-form-holder').removeClass('search');
            }

        }
    });


    jQ('#search_input, #uloop-navigation-top #searchform input[name="s"]').keydown(function (event) {

        if (window.CodeAdapter.project_type !== 'wordpress_plugin' && jQ(this).attr('id') == 'search_input') {
            return;
        }

        var elem = jQ(this);
        var form = jQ(elem).parents('form').get(0);

        if (typeof(jQ('.header-search-form-holder')) !== "undefined") {
            jQ('.header-search-form-holder').addClass('search');
        }

        jQ(elem).attr('autocomplete', 'off');

        if (event.keyCode === 13 || event.keyCode === 40 || event.keyCode === 38 || event.keyCode === 39) {
            var total_idx = jQ('#fast_search_results .fast_search_item', form).length;

            // enter
            if (event.keyCode === 13) {
                jQ('#fast_search_results .fast_search_focus_item .fast_search_image img', form).click();

            }
            // right
            else if (event.keyCode === 39 && jQ('.search-autocomplete', form).text().length) {
                jQ('#search_input', form).val(jQ('.search-autocomplete', form).text());
            }
            // down
            else if (event.keyCode === 40) {
                jQ('#fast_search_results .fast_search_item', form).removeClass('fast_search_focus_item');

                window.search_row_index++;

                if (window.search_row_index === total_idx) {
                    window.search_row_index = 0;
                }

                var FocusRow = jQ('.fast_search_item:eq(' + window.search_row_index + ')', form);
                jQ(FocusRow).addClass('fast_search_focus_item');

            }
            // up
            else if (event.keyCode === 38) {
                jQ('#fast_search_results .fast_search_item', form).removeClass('fast_search_focus_item');

                window.search_row_index--;

                if (window.search_row_index === -1) {
                    window.search_row_index = total_idx - 1;
                }

                var FocusRow = jQ('.fast_search_item:eq(' + window.search_row_index + ')', form);
                jQ(FocusRow).addClass('fast_search_focus_item');
                return false;
            }

            return false;
        }
        // esc if (event.keyCode === 27)
        else {
            jQ('#fast_search_results .fast_search_item', form).removeClass('fast_search_focus_item');
            window.search_row_index = -1;

            if (typeof(form.getElementsByClassName('search-autocomplete')) != 'undefined' && typeof(form.getElementsByClassName('search-autocomplete')[0]) != 'undefined') {
                form.getElementsByClassName('search-autocomplete')[0].innerHTML = '';
            }
        }
    });


    jQ("#search_input, #uloop-navigation-top #searchform input[name='s']").keyup(function () {
        if (window.CodeAdapter.project_type !== 'wordpress_plugin' && jQ(this).attr('id') == 'search_input') {
            return;
        }

        var elem = jQ(this);
        var form = jQ(elem).parents('form').get(0);

        if (window.search_value != jQ(this).val()) {
            if (!jQ("#fast_search_results", form).find(".fast_search_overflow").get(0))
                jQ("#fast_search_results", form).html("<div class='fast_search_overflow'>" + jQ("#fast_search_results").html() + "</div>");

            ProcessFastSearchAutoFill(elem, form);

            clearTimeout(window.search_timer);

            window.search_timer = setTimeout(function () {
                ProcessFastSearch(elem, form, true);
            }, 400);
            window.search_value = jQ(this).val();
            if (jQ(elem).attr('name') == 's') {
                jQ('.clear_search_results').show();
            }
        }

        if (!jQ(this).val().length) {
            jQ("#fast_search_results", form).css('display', 'none');
            if (jQ(elem).attr('name') == 's') {
                jQ('.clear_search_results').hide();
            }
        }

    });
    jQ("#search_input,  #uloop-navigation-top #searchform input[name='s']").focus(function () {

        if (window.CodeAdapter.project_type !== 'wordpress_plugin' && jQ(this).attr('id') == 'search_input') {
            return;
        }
        var elem = jQ(this);
        var form = jQ(elem).parents('form').get(0);

        if (window.search_value != jQ(elem).val()) {
            if (!jQ("#fast_search_results", form).find(".fast_search_overflow").get(0))
                jQ("#fast_search_results", form).html("<div class='fast_search_overflow'>" + jQ("#fast_search_results").html() + "</div>");

            clearTimeout(window.search_timer);

            window.search_timer = setTimeout(function () {
                ProcessFastSearch(elem, form);
            }, 400);
            window.search_value = jQ(this).val();
        }
        else if (window.search_value == jQ(this).val() && jQ("#fast_search_results", form).css('display') == 'none') {
            jQ("#fast_search_results", form).css('display', '');
        }

        if (!jQ(this).val().length) {
            jQ("#fast_search_results", form).css('display', 'none');
        }

    });

    jQ("#search_form").submit(function () {

        if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
            return;
        }

        var form = jQ(this).parents('form').get(0);

        jQ('input[name="verification"]', form).remove();

        // if (window.search_value != jQ("#search_input").val()) {
        //     if (!jQ("#fast_search_results").find(".fast_search_overflow").get(0))
        //         jQ("#fast_search_results").html("<div class='fast_search_overflow'>" + jQ("#fast_search_results").html() + "</div>");
        //
        //     clearTimeout(window.search_timer);
        //
        //     window.search_timer = setTimeout(function () {
        //         ProcessFastSearch(jQ("#search_input"), form);
        //     }, 400);
        //     window.search_value = jQ("#search_input").val();
        // }
        // else if (window.search_value == jQ("#search_input").val() && jQ("#fast_search_results").css('display') == 'none') {
        //     jQ("#fast_search_results").css('display', '');
        // }
        //
        // if (!jQ("#search_input").val().length) {
        //     jQ("#fast_search_results").css('display', 'none');
        // }
        //
        // return false;
    });

});



// function ProcessFastSearch(elem, form)
// {
//     search_str = jQ(elem).val();

// 	if(search_str == 'Search') return;

// 	  window.CodeAdapter.ajaxRequest({
// 				url: "ajax_content.php",
// 				data: "action=get_search_json&s="+search_str,
// 				type: "POST",
// 				dataType: "json",
// 				success:function(json){

// 				   if((json.classifieds.items.length || json.news.items.length) && search_str.length)
// 				   {

// 						if(!window.results_container)
// 						{
// 							window.results_container = document.createElement('div');
// 							window.results_container.className = "fast_search_results";
// 							window.results_container.id = "fast_search_results";

// 							jQ(form).append(window.results_container);
// 						}


// 						jQ(window.results_container).css("display","");

// 						jQ(window.results_container).click(function(event){
// 							 event.stopPropagation();
// 						 });

// 						jQ("#search_form .overflow").click(function(event){
// 							 event.stopPropagation();
// 						 });

// 						while( window.results_container.hasChildNodes() ){
// 							 window.results_container.removeChild(window.results_container.lastChild);
// 						 }

// 						//CLASSIFIEDS

// 						if(json.classifieds.items.length)
// 						{

// 							var classifieds_title = document.createElement('div');
// 							classifieds_title.className = "fast_search_title";
// 							classifieds_title.innerHTML = "Classifieds ("+json.classifieds.total_found+")";

// 							window.results_container.appendChild(classifieds_title);

// 							for (key in json.classifieds.items) {

// 								 var item_container = document.createElement('div');
// 								 item_container.className = "fast_search_item";

//                                                                 var item_container_text = document.createElement('div');
// 								 item_container_text.className = "fast_search_item_text";

// 								 var item = json.classifieds.items[key];

// 								 var linkEl = document.createElement('a');
// 								 linkEl.setAttribute("href",item.link);
// 								 linkEl.innerHTML = item.title;
// 								  if(item.link.indexOf('view_external_link') != -1)
// 									linkEl.setAttribute("target","_blank");
// 								 linkEl.className="fast_search_link";

// 								 var imgLinkEl = document.createElement('a');
// 								 imgLinkEl.setAttribute("href",item.link);
// 								 if(item.link.indexOf('view_external_link') != -1)
// 									imgLinkEl.setAttribute("target","_blank");

// 								 imgLinkEl.className="fast_search_image";

// 								 var imgEl = document.createElement('img');
// 								 if(item.image)
// 								 {
// 									 imgEl.setAttribute("src",item.image);
//                                                                         imgEl.onerror = function(){
//                                                                                this.setAttribute("src",window.imagesDomain+'/img/no-image-small.jpg');
//                                                                                this.className="no-photo";
//                                                                         }
// 								 }
// 								 else
// 								 {
// 									 imgEl.setAttribute("src",window.imagesDomain+'/img/no-image-small.jpg');
// 									 imgEl.className="no-photo";
// 								 }

// 								 imgLinkEl.appendChild(imgEl);


// 								 var additional_html = document.createElement('div');
// 								 additional_html.innerHTML = item.additional_html;
// 								 additional_html.className = "fast_search_additional";

// 								 item_container.appendChild(imgLinkEl);

//                                                                 item_container_text.appendChild(linkEl);

//                                                                 item_container_text.appendChild(additional_html);

// 								 item_container.appendChild(item_container_text);

// 								 var clear = document.createElement('div');
// 								 clear.className = "clear";
// 								 item_container.appendChild(clear);

// 								 window.results_container.appendChild(item_container);
// 							}

// 							var see_all_classifieds_container = document.createElement('div');
// 							see_all_classifieds_container.className = 'fast_search_see_all';
// 							var see_all_link = document.createElement('a');
// 							see_all_link.innerHTML = 'See all Classifieds results';
//                                                        if(typeof(plugin_data) !== 'undefined'){
//                                                            see_all_link.setAttribute("href","/"+plugin_data.uloop_university_slug+"/classifieds?s="+search_str);
//                                                        }
//                                                        else{
//                                                            see_all_link.setAttribute("href","/classifieds?s="+search_str);
//                                                        }
// 							see_all_classifieds_container.appendChild(see_all_link);
// 							window.results_container.appendChild(see_all_classifieds_container);

// 						}
// 						//NEWS

// 						if(json.news.items.length)
// 						{
// 							var news_title = document.createElement('div');
// 							news_title.className = "fast_search_title";
// 							news_title.innerHTML = "News ("+json.news.total_found+")";

// 							window.results_container.appendChild(news_title);

// 							for (key in json.news.items)
// 							{

// 								 var item_container = document.createElement('div');
// 								 item_container.className = "fast_search_item";

//                                                                 var item_container_text = document.createElement('div');
// 								 item_container_text.className = "fast_search_item_text";

// 								 var item = json.news.items[key];

// 								 var linkEl = document.createElement('a');
// 								 linkEl.setAttribute("href",item.link);
// 								 linkEl.innerHTML = item.title;
// 								 linkEl.className="fast_search_link";

// 								 var imgLinkEl = document.createElement('a');
// 								 imgLinkEl.setAttribute("href",item.link);
// 								 imgLinkEl.className="fast_search_image";

// 								 var imgEl = document.createElement('img');
// 								 if(item.image)
// 								 {
// 									 imgEl.setAttribute("src",item.image);
// 								 }
// 								 else
// 								 {
// 									 imgEl.setAttribute("src",window.imagesDomain+'/img/no-image-small.jpg');
// 									 imgEl.className="no-photo";
// 								 }

// 								 imgLinkEl.appendChild(imgEl);

// 								 var additional_html = document.createElement('div');
// 								 additional_html.innerHTML = item.additional_html;
// 								 additional_html.className = "fast_search_additional";

// 								 item_container.appendChild(imgLinkEl);

//                                                                 item_container_text.appendChild(linkEl);

//                                                                 item_container_text.appendChild(additional_html);

// 								 item_container.appendChild(item_container_text);

// 								 var clear = document.createElement('div');
// 								 clear.className = "clear";
// 								 item_container.appendChild(clear);

// 								 window.results_container.appendChild(item_container);
// 							}

// 							var see_all_news_container = document.createElement('div');
// 							see_all_news_container.className = 'fast_search_see_all';
// 							var see_all_link = document.createElement('a');
// 							see_all_link.innerHTML = 'See all News results';
// 							see_all_link.setAttribute("href","/news?s="+search_str);
// 							see_all_news_container.appendChild(see_all_link);
// 							window.results_container.appendChild(see_all_news_container);
// 						}
// 				   }
// 				   else
// 				   {
// 					   jQ("#fast_search_results",form).css('display','none');
// 				   }
// 				}
// 		});

// 		if(window.CodeAdapter.project_type === 'wordpress_plugin'){
// 			jQ.ajax({
//                     url: location.origin,
//                     data: "origin_ajax_request=1&s=University%20of%20New%20Haven",
//                     type: "GET",
//                     dataType: "json",
//                     success:function(json){
//                     	if(json.news.items.length)
// 								{
// 									var news_title = document.createElement('div');
// 									news_title.className = "fast_search_title";
// 									news_title.innerHTML = "News ("+json.news.total_found+")";

// 									window.results_container.appendChild(news_title);

// 									for (key in json.news.items)
// 									{

// 										 var item_container = document.createElement('div');
// 										 item_container.className = "fast_search_item";

// 		                                                                var item_container_text = document.createElement('div');
// 										 item_container_text.className = "fast_search_item_text";

// 										 var item = json.news.items[key];

// 										 var linkEl = document.createElement('a');
// 										 linkEl.setAttribute("href",item.link);
// 										 linkEl.innerHTML = item.title;
// 										 linkEl.className="fast_search_link";

// 										 var imgLinkEl = document.createElement('a');
// 										 imgLinkEl.setAttribute("href",item.link);
// 										 imgLinkEl.className="fast_search_image";

// 										 var imgEl = document.createElement('img');
// 										 if(item.image)
// 										 {
// 											 imgEl.setAttribute("src",item.image);
// 										 }
// 										 else
// 										 {
// 											 imgEl.setAttribute("src",window.imagesDomain+'/img/no-image-small.jpg');
// 											 imgEl.className="no-photo";
// 										 }

// 										 imgLinkEl.appendChild(imgEl);

// 										 var additional_html = document.createElement('div');
// 										 additional_html.innerHTML = item.additional_html;
// 										 additional_html.className = "fast_search_additional";

// 										 item_container.appendChild(imgLinkEl);

// 		                                                                item_container_text.appendChild(linkEl);

// 		                                                                item_container_text.appendChild(additional_html);

// 										 item_container.appendChild(item_container_text);

// 										 var clear = document.createElement('div');
// 										 clear.className = "clear";
// 										 item_container.appendChild(clear);

// 										 window.results_container.appendChild(item_container);
// 									}

// 									var see_all_news_container = document.createElement('div');
// 									see_all_news_container.className = 'fast_search_see_all';
// 									var see_all_link = document.createElement('a');
// 									see_all_link.innerHTML = 'See all News results';
// 									see_all_link.setAttribute("href","/news?s="+search_str);
// 									see_all_news_container.appendChild(see_all_link);
// 									window.results_container.appendChild(see_all_news_container);
// 								}
//                     }
//             });
// 		}
// }



//////////////////////////////////////


/* repliesDetail with merge data from csa */
//function repliesDetailMerge(e, idAd,hashTitle,dateStart,dateEnd) {
//
//    var  hashTitle = "." + hashTitle,
//            dateStart = dateStart,
//            dateEnd=dateEnd,
//            json;
//
//    popup
//    ({
//        popup_id:"admin_preloader",
//        speed:400,
//        close_button:false,
//        hide_on_click: false
//    });
//    window.CodeAdapter.ajaxRequest({
//        url: "index.php",
//        data: "action=show_replies_detail&ad="+idAd+"&dateStart=" + dateStart + "&dateEnd=" + dateEnd,
//        type: "POST",
//        dataType: "html",
//        success: function(data){
//            json = JSON.parse(jQ(hashTitle).html());
//            var additionalData = '';
//
//            if(data.indexOf('<tbody>') !== -1) {
//                additionalData+= "<tr>";
//
//                for(var i = 0; i < json.length; i++) {
//                    additionalData+='<td>' + json[i]['name'] + '</td>'
//                    +     '<td>' + json[i]['email'] + '</td>'
//                    +     '<td>' + json[i]['phone'] + '</td>'
//                    +     '<td>' + json[i]['uformat'] + '</td>'
//                }
//                additionalData+="</tr>";
//            } else {
//                if(json !== null && typeof  json !== "undefined") {
//                    for(var i = 0; i < json.length; i++) {
//                        additionalData+= '<div class="table_tr">'
//                        +    '<div class="table_td td-fixed">' + json[i]['name'] + '</div>'
//                        +     '<div class="table_td td-fixed">' + json[i]['email'] + '</div>'
//                        +     '<div class="table_td td-fixed">' + json[i]['phone'] + '</div>'
//                        +     '<div class="table_td td-fixed">' + json[i]['uformat'] + '</div>'
//                        +     '</div>';
//                    }
//                }
//            }
//
//            data = data.replace("<!-- replace-me -->",additionalData);
//
//            jQ('#replies_detail').html(data);
//            ShowPopup('replies_detail');
//        }
//    });
//    jQ.event.fix(e).preventDefault;
//}


/* repliesDetail with merge data from csa */






/******** END Question&Answers ********/


/******** Start SCEditor ********/



/******** End Profile image upload ********/


/******** Start Comment photo upload *******/



/******** End Comment photo upload *******/






(function (jQ) {
    'use strict';

    jQ.fn.extend({
        customSelect: function (options) {
            // filter out <= IE6
            if (typeof document.body.style.maxHeight === 'undefined') {
                return this;
            }
            var defaults = {
                    customClass: 'customSelect',
                    mapClass:    true,
                    mapStyle:    true
                },
                options = jQ.extend(defaults, options),
                prefix = options.customClass,
                changed = function ($select,customSelectSpan) {
                    var currentSelected = $select.find(':selected'),
                        customSelectSpanInner = customSelectSpan.children(':first'),
                        html = currentSelected.html() || '&nbsp;';

                    customSelectSpanInner.html(html);

                    if (currentSelected.attr('disabled')) {
                        customSelectSpan.addClass(getClass('DisabledOption'));
                    } else {
                        customSelectSpan.removeClass(getClass('DisabledOption'));
                    }

                    setTimeout(function () {
                        customSelectSpan.removeClass(getClass('Open'));
                        jQ(document).off('mouseup.customSelect');
                    }, 60);
                },
                getClass = function(suffix){
                    return prefix + suffix;
                };

            return this.each(function () {
                var $select = jQ(this),
                    customSelectInnerSpan = jQ('<span />').addClass(getClass('Inner')),
                    customSelectSpan = jQ('<span />');

                $select.after(customSelectSpan.append(customSelectInnerSpan));

                customSelectSpan.addClass(prefix);

                if (options.mapClass) {
                    customSelectSpan.addClass($select.attr('class'));
                }
                if (options.mapStyle) {
                    customSelectSpan.attr('style', $select.attr('style'));
                }

                $select
                    .addClass('hasCustomSelect')
                    .on('render.customSelect', function () {
                        changed($select,customSelectSpan);
                        $select.css('width','');
                        console.log($select);
                        var selectBoxWidth = parseInt($select.outerWidth(), 10) -
                            (parseInt(customSelectSpan.outerWidth(), 10) -
                                parseInt(customSelectSpan.width(), 10));

                        // Set to inline-block before calculating outerHeight
                        customSelectSpan.css({
                            display: 'inline-block'
                        });

                        var selectBoxHeight = customSelectSpan.outerHeight();

                        if ($select.attr('disabled')) {
                            customSelectSpan.addClass(getClass('Disabled'));
                        } else {
                            customSelectSpan.removeClass(getClass('Disabled'));
                        }

                        customSelectInnerSpan.css({
                            width:   selectBoxWidth,
                            display: 'inline-block'
                        });

                        $select.css({
                            '-webkit-appearance': 'menulist-button',
                            width:                customSelectSpan.outerWidth(),
                            position:             'absolute',
                            opacity:              0,
                            height:               selectBoxHeight,
                            fontSize:             customSelectSpan.css('font-size')
                        });
                    })
                    .on('change.customSelect', function () {
                        customSelectSpan.addClass(getClass('Changed'));
                        changed($select,customSelectSpan);
                    })
                    .on('keyup.customSelect', function (e) {
                        if(!customSelectSpan.hasClass(getClass('Open'))){
                            $select.trigger('blur.customSelect');
                            $select.trigger('focus.customSelect');
                        }else{
                            if(e.which==13||e.which==27){
                                changed($select,customSelectSpan);
                            }
                        }
                    })
                    .on('mousedown.customSelect', function () {
                        customSelectSpan.removeClass(getClass('Changed'));
                    })
                    .on('mouseup.customSelect', function (e) {

                        if( !customSelectSpan.hasClass(getClass('Open'))){
                            // if FF and there are other selects open, just apply focus
                            if(jQ('.'+getClass('Open')).not(customSelectSpan).length>0 && typeof InstallTrigger !== 'undefined'){
                                $select.trigger('focus.customSelect');
                            }else{
                                customSelectSpan.addClass(getClass('Open'));
                                e.stopPropagation();
                                jQ(document).one('mouseup.customSelect', function (e) {
                                    if( e.target != $select.get(0) && jQ.inArray(e.target,$select.find('*').get()) < 0 ){
                                        $select.trigger('blur.customSelect');
                                    }else{
                                        changed($select,customSelectSpan);
                                    }
                                });
                            }
                        }
                    })
                    .on('focus.customSelect', function () {
                        customSelectSpan.removeClass(getClass('Changed')).addClass(getClass('Focus'));
                    })
                    .on('blur.customSelect', function () {
                        customSelectSpan.removeClass(getClass('Focus')+' '+getClass('Open'));
                    })
                    .on('mouseenter.customSelect', function () {
                        customSelectSpan.addClass(getClass('Hover'));
                    })
                    .on('mouseleave.customSelect', function () {
                        customSelectSpan.removeClass(getClass('Hover'));
                    })
                    .trigger('render.customSelect');
            });
        }
    });
})(jQuery);



onResourcesAndDOMReady(function(){

    jQ('body').on('click', '.image-container .delete', function () {
        jQ(this).closest('.image-container').remove();
    });

    if(jQ().fancybox) {
        jQ("[data-fancybox]").fancybox({
            animationEffect: "fade",
            idleTime: false
        });

        jQ("[yt-auto-close-fancybox]").fancybox({
            animationEffect: "fade",
            idleTime: false,
            helpers: {
                media: {
                    youtube: {
                        params: {
                            autoplay: true,
                            rel: false,
                        }
                    }
                },
            },
            afterShow : function(){
                if(this.type == "iframe"){
                    var iframe_id = jQ(".fancybox-content iframe").attr("id");

                    var head = document.getElementsByTagName('head')[0];
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = "https://www.youtube.com/player_api";
                    head.appendChild(script);

                    var waitYT_API = setInterval(function () {
                        if (typeof(YT) === 'object' && typeof(YT.Player) === 'function') {
                            clearInterval(waitYT_API);
                            var player = new YT.Player(iframe_id, {
                                events: {
                                    'onStateChange': onFancyboxPlayerStateChange
                                }
                            });
                        }
                    }, 100);
                }
            },
        });

        jQ(".quick_view").fancybox({
            baseClass: 'image-source-preview',
            transitionEffect: "fade",
            transitionDuration: 500,
            // Base template for layout
            baseTpl	:
                '<div class="fancybox-container" role="dialog" tabindex="-1">' +
                '<div class="fancybox-bg"></div>' +
                '<div class="fancybox-inner">' +
                '<div class="fancybox-infobar">' +
                '<button data-fancybox-prev title="{{PREV}}" class="fancybox-button fancybox-button--left"></button>' +
                '<div class="fancybox-infobar__body">' +
                '<span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span>' +
                '</div>' +
                '<button data-fancybox-next title="{{NEXT}}" class="fancybox-button fancybox-button--right"></button>' +
                '</div>' +
                '<div class="fancybox-toolbar">' +
                '{{BUTTONS}}' +
                '</div>' +
                '<div class="fancybox-navigation">' +
                '<button data-fancybox-prev title="{{PREV}}" class="fancybox-arrow fancybox-arrow--left" />' +
                '<button data-fancybox-next title="{{NEXT}}" class="fancybox-arrow fancybox-arrow--right" />' +
                '</div>' +
                '<div class="image-source-preview-wrap">' +
                '<div class="fancybox-stage"></div>' +
                '<div class="fancybox-caption-wrap">' +
                '<div class="fancybox-caption"></div>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>',
            onInit: function (instance) {

                /*
                    #2 Add product form
                    ===================
                */

                var $element = instance.group[instance.currIndex].opts.$orig;
                var form_id = $element.data('qw-form');

                instance.$refs.container.find('.image-source-preview-wrap').append(
                    // In this example, this element contains the form
                    jQ('<div>').addClass('image-source-preview-description').append(jQ('#' + form_id).clone(true).removeClass('hidden-block'))
                );

            },

            beforeShow: function (instance) {

            }

        });

    }


    // processImagesScale();

    jQ('body').on('click', '.response-see-more', function () {
        var parent_container = jQ(this).closest('.response-text');
        jQ(parent_container).find('.short').hide();
        jQ(this).hide();
        jQ(parent_container).find('.full').show();
    });

    if(typeof jQ(".block-scroll") !== typeof undefined && jQ(".block-scroll").length) {
        jQ(".block-scroll").on('click', function(event) {
            if (this.hash !== "") {
                event.preventDefault();

                var hash = this.hash;

                var pos = jQ(hash).offset().top - 55;
//          console.log(jQ(hash).offset().top);
//          console.log(pos);

                jQ('html, body').animate({
                    scrollTop: pos
                }, 800, function(){
                });
            }
        });
    }

    if (typeof jQ('.paid-jobs-plain .container-banner .btn-orange') !== typeof undefined && jQ('.paid-jobs-plain .container-banner .btn-orange').length) {
        requirejs(['util/elementInView'], function (Utils) {
            var isElementInView = Utils.instance.isElementInView(jQ('.container-banner .btn-orange'), false);

            if (isElementInView) {
                Utils.instance.hide(jQ('.jobs-footer-menu'));
                Utils.instance.show(jQ('.jobs-header'));
            } else {
                Utils.instance.hide(jQ('.jobs-header'));
                Utils.instance.show(jQ('.jobs-footer-menu'));
            }

            jQ(window).on('scroll touchmove', function(e) {
                console.log(e);
                var isElementInView = Utils.instance.isElementInView(jQ('.container-banner .btn-orange'), false);
                if (isElementInView) {
                    Utils.instance.hide(jQ('.jobs-footer-menu'));
                    Utils.instance.show(jQ('.jobs-header'));
                } else {
                    Utils.instance.hide(jQ('.jobs-header'));
                    Utils.instance.show(jQ('.jobs-footer-menu'));
                }
            });
        });
    }


    if (typeof jQ('#header-bar-mobile-left-container') !== typeof undefined && jQ('#header-bar-mobile-left-container').length
        && window.m_w_width <= 900
        && window.location.pathname === "/") {

        var $docEl = jQ('html, body');

        jQ.lockBody = function() {
            $docEl.css({
                height: "100%",
//                overflow: "auto"

            });
        }

        jQ.unlockBody = function() {
            $docEl.css({
                height: "auto"
//                overflow: "unset"
            });
        }

        jQ.unlockBody();
        if (jQ(window).scrollTop() > 52) {
            jQ('#header-bar-mobile-left-container').fadeOut();
            jQ('#header-bar-mobile-right-container').fadeOut();
        } else {
            // if(jQ('#header-bar-mobile-left-container').css('display') == 'none') {
            jQ('#header-bar-mobile-left-container').fadeIn();
            jQ('#header-bar-mobile-right-container').fadeIn();
            // }
        }
        jQ.lockBody();

        jQ(window).on('scroll touchmove', function(e) {
            jQ.unlockBody();

            if (jQ(window).scrollTop() > 52) {
                jQ('#header-bar-mobile-left-container').fadeOut();
                jQ('#header-bar-mobile-right-container').fadeOut();
            } else {
                // if(jQ('#header-bar-mobile-left-container').css('display') == 'none') {
                jQ('#header-bar-mobile-left-container').fadeIn();
                jQ('#header-bar-mobile-right-container').fadeIn();
                // }
            }

//                jQ.lockBody();
        });


    }

});

/*
 *
 * Comment message JS END
 *
 */



onResourcesAndDOMReady(function(){
    //main slider

    if (document.getElementById('main_page_slider')) {
        var main_slider = new slider({
            slider_id: "main_page_slider",
            speed: 400,
            play: 500000000
        });
    }
    if (document.getElementById('tab1') && document.getElementById('tab_content1')) {
        var tab1 = new tabs({
            tab_menu: "tab1",
            tabs_container: "tab_content1"
        });
    }

    if (document.getElementById('popup_tab_menu') && document.getElementById('reg_forms_container')) {
        var reg_tab = new tabs({
            tab_menu: "popup_tab_menu",
            tabs_container: "reg_forms_container"
        });
    }

    if ($('state') && $('school')) {
        $('state').selectedIndex = 0;
        $('school').selectedIndex = 0;
    }
});
